/*jshint esversion: 6 */
JS = {
  fire : function(func,funcname, args){
    var namespace = BO;  
    funcname = (funcname === undefined) ? 'init' : funcname;
    if (func !== '' && namespace[func] && typeof namespace[func][funcname] == 'function'){
      namespace[func][funcname](args);
    } 
  }, 
  loadEvents : function(){
    JS.fire('common');
    var template = document.body.dataset.intendedTemplate;
    JS.fire(template);
  } 
}; 
document.addEventListener('DOMContentLoaded', JS.loadEvents);

BO = {
  'common' : {
    init : function(){ 
      loaded();
      logoanim();
      shareLinks();
      menulink();
      mailchimp();
      lightbox();
      network();
    }
  },
  'exhibition' : {
    init : function(){ 
      readmoreArtists();
      readmoreRendezvous();
      gallery();
      scrollr();
      setCSSProps();
      extracover();
      ajaxextra();
    }
  },
  'home' : {
    init : function(){ 
      extracover();
      flickity();
    }
  },
  'artlibrary' : {
    init : function(){ 
      grid();
      scrollr();
    }
  },
  'publication' : {
    init : function(){ 
      readmoreArtists();          
    }
  },
  'residences' : {
    init : function(){ 
      scrollr();
      grid();
    }
  },
  'residence' : {
    init : function(){ 
      readmoreArtists();
      readmoreRendezvous();
      gallery();
    }
  },
  'event' : {
    init : function(){ 
      readmoreArtists();
      readmoreRendezvous();
      gallery();
    }
  },
  'archives': {
    init : function(){   
      grid();
    }        
  },
  'article': {
    init : function(){   
      grid();
    }        
  },
  'page': {
    init : function(){   
      scrollr();
      gallery();
    }        
  },
  'infos': {
    init : function(){   
      scrollr();
      doMap();
    }        
  },
  'extrapage': {
    init : function(){   
      extracover();
      grid();
      gallery();
      makeAudioPlayers();
      makeVideoPlayers();
    }        
  }
};



loaded = function(){
  document.body.classList.add('loaded');
};


logoanim = function(){
  var logos = document.querySelectorAll('.ob');
  logos.forEach(function(logo){
    logo.addEventListener("animationiteration",function(){
      if(!logo.parentNode.classList.contains('animated')){
        logo.classList.remove("animated");     
        logo.classList.add("endanim");      
      }
    },false);  
  });
  var headers = document.querySelectorAll(".site-header-h1");
  headers.forEach(function(header){
    var logoanim = header.querySelector(".logo-anim");
    header.addEventListener('mouseover', function(){  
      logos.forEach(function(logo){
        logo.classList.remove('endanim');
        logo.classList.add("animated");  
      });
      logoanim.classList.add("animated");        
    });
    header.addEventListener('mouseout', function(){     
      logoanim.classList.remove("animated");      
    });
  });
};

shareLinks = function(){
  // $('.share a').click(function() {
  //     var url= $(this).attr('href');
  //     window.open(url,'popup','width=500,height=300')
  //     return false;
  // });
};


network = function(){
  var form = document.querySelector("#sib-form");
  var input = document.querySelector('#EMAIL');
  if(!input) return;
  var checkbox = document.querySelector('#OPT_IN');
  var feedback = document.querySelector('#subscription-feedback');
  input.addEventListener('focus', function(){
    form.classList.add('focused');
  });
  input.addEventListener('blur', function(){
    if(input.value == ""){
      setTimeout(() => {
        form.classList.remove('focused');
      }, 100);
    }
  });
  form.addEventListener('submit', function(e){
    var action = form.getAttribute('action');
    if(!checkbox.checked){
      e.preventDefault();
      feedback.textContent = "Veuillez confirmer avoir pris connaissance de notre politique de confidentialité et mentions légales.";
    } else {      
    //   e.preventDefault();      
    //   fetch(action, {
    //     method: 'post',
    //     mode: 'cors', // no-cors, *cors, same-origin
    //     cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
    //     credentials: 'same-origin', // include, *same-origin, omit
    //     headers: {
    //       'Content-Type': 'application/json'
    //     },
    //     body: JSON.stringify({ email: form.EMAIL.value})
    //   }).then(function(response) {
    //     return response.json();
    //   }).then(function(data) {
    //     console.log(data);
    //     //Success code goes here
    //     console.log('form submited')
    //   }).catch(function(err) {
    //     //Failure
    //     console.log(err);
    //     console.log('Error')
    //   });
    }
  });
  window.addEventListener('load', function(){
    if(input.value != ""){
      form.classList.add('focused');
    }
  });
};

scrollr = function(){
  var wh = parseInt( window.innerHeight );
  var scrollOptions = { capture: false, passive: true };
  var splash = document.querySelector('#fullheader');
  function onScroll(event) {
    let y = window.scrollY;
    splash.style.setProperty('--y', `${y}px`);
  }
  if (splash) {
    if ('IntersectionObserver' in window ) {
      const observer = new IntersectionObserver(entries => {
        let [{ isIntersecting }] = entries;
        if (isIntersecting) {
          window.addEventListener('scroll', onScroll, scrollOptions);
        } else {
          window.removeEventListener('scroll', onScroll, scrollOptions);
        }
      });
      observer.observe(splash);
    } else {
      window.addEventListener('scroll', onScroll, scrollOptions);
    }
  }

  var scroll = document.querySelector('.scroll');
  if(scroll){
    scroll.onclick = function(e){
      e.preventDefault();
      const href = this.getAttribute("href");
      const offsetTop = document.querySelector(href).offsetTop;
      window.scroll({
        top: offsetTop,
        behavior: "smooth"
      });
    };
  }
};

menulink = function() {
  // menulink
  var menulinks = document.querySelectorAll('.menulink');
  var nav = document.querySelector('#nav');
  var page = document.querySelector('#page');
  menulinks.forEach(menulink => {
    menulink.addEventListener('click', () =>{
      nav.classList.toggle('opened');
      menulinks.forEach(m => {
        m.classList.toggle('opened');
      });
      document.body.classList.toggle('locked');
      if(nav.classList.contains('opened')){
        // page.scrollIntoView();
      }
    });
  });

  // search
  var search_button = document.querySelector('#show-search');
  var form = search_button.closest('form');
  var q = document.querySelector('#q');
  search_button.addEventListener('click', (e) =>{
    console.log("yo");
    e.stopPropagation();
    if(nav.classList.contains('search-visible')){
      form.submit();
    } 
    nav.classList.toggle('search-visible');
    q.focus();
    q.select();  
  });
  q.addEventListener('blur', (e) =>{
    setTimeout(function(){
      nav.classList.remove('search-visible');
    }, 50);
  });
};

cook = function(){
  // var cookiesBanner = new CookiesEuBanner(function(){
  //     (function(i,s,o,g,r,a,m){i['GoogleAnalyticsObject']=r;i[r]=i[r]||function(){
  //     (i[r].q=i[r].q||[]).push(arguments)},i[r].l=1*new Date();a=s.createElement(o),
  //     m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m)
  //     })(window,document,'script','//www.google-analytics.com/analytics.js','ga');

  //     ga('create', 'UA-38417889-1', 'auto');
  //     ga('send', 'pageview');
  // }, true);
};

/* ---------------------------------------------------------------------------- */
// Map on contact / info page
/* ---------------------------------------------------------------------------- */

doMap = function(){
  var center = [ 46.302618, 2.399459 ];
  var zoom = 5;
  var map = L.map('map_canvas', {
    attributionControl:false,
    scrollWheelZoom: false
  }).setView(center, zoom);
  var layer = new L.StamenTileLayer("toner");
  map.addLayer(layer);
  var arrow = L.icon({
      iconUrl: 'assets/images/bomap.svg',
      iconSize:     [80, 40], // size of the icon
      iconAnchor:   [72, 2], // point of the icon which will correspond to marker's location
  });
  var marker = L.marker([ 43.302618, -0.399459 ], {icon: arrow}).addTo(map);
  // marker.bindPopup('You might be here…');
  marker.addEventListener('click', function(){
    map.setView([ 43.302618, -0.399459 ], 16);
    // map.panTo([ 46.302618, 2.399459 ])
  });
};

readmoreRendezvous = function(){
  if(window.location.hash){
      var hash = window.location.hash,
        is_rdv_hash = hash.indexOf('rdv') !== -1,
        rdv = document.querySelector(window.location.hash);
      if(is_rdv_hash){
        rdv.scrollIntoView();
        rdv.classList.add('highlight'); 
        setCSSProps();
      }
  }
  var rdvs = document.querySelectorAll('.rendezvous');
  rdvs.forEach((rdv) => {
    rdv.onclick = (e) => {
      if(e.target.matches(".rendezvous-link")){
        e.preventDefault();
        history.replaceState("", document.title, "#" + rdv.id);
        e.stopPropagation();
        var highlighted = document.querySelector('.highlight');
        if(highlighted && highlighted != rdv) {
          highlighted.classList.remove('highlight');
        }
        if(rdv.classList.contains('highlight')) {          
          rdv.classList.remove('highlight');
        } else {
          rdv.classList.add('highlight');
          // rdv.scrollIntoView();
        }
      }
    };
  });
};

readmoreArtists = function(){
  var artistbios = document.querySelectorAll('.artistbio');
  artistbios.forEach((artistbio) => {
    var readmore = document.createElement('button');
      readmore.textContent = "En savoir plus";
      readmore.className = "readmore";
    var readless = document.createElement('button'); 
      readless.textContent = "Réduire";
      readless.className = "readless";
    var paragraphs = artistbio.children,
      firstp = paragraphs[0];

    if(paragraphs.length>2){    
      firstp.appendChild(readmore);
      artistbio.appendChild(readless);
      
      readmore.onclick = function(e){
        e.preventDefault();
        artistbio.classList.add('visible');
        readmore.style.display = "none";
        readless.style.display = "block";
        artistbio.scrollIntoView();
      };
      readless.onclick = function(e){
        e.preventDefault();
        artistbio.scrollIntoView();
        artistbio.classList.remove('visible');
        readmore.style.display = "block";
        readless.style.display = "none";
      };
    } 
  });
};


nicyfyForm = function(){
  // $("#address-group").css("display", "none");
  
  // $("#address-group-link input").click(function () {
  //     if ($(this).is(":checked")) {
  //         $("#address-group").show();
  //     } else {
  //         $("#address-group").hide();
  //     }
  // });
};

mailchimp = function(){


  
  // $.ajaxChimp = {
  //     responses: {
  //         'Nous vous avons envoyé un mail de confirmation. Validez votre abonnement en cliquant sur le lien fourni. Merci.' : 0,
  //         'Merci de saisir une adresse e-mail valide'                                                        : 1,
  //         'L’adresse ne peut contenir qu’un seul @'                                          : 2,
  //         'Le domaine (la partie après l’@) est invalide'    : 3,
  //         'Le nom d’tilisateur (la partie avant l’@) est invalide' : 4,
  //         'Cette adresse semble fausse ou invalide'       : 5
  //     },
  //     translations: {
  //         'en': null
  //     },
  //     init: function (selector, options) {
  //         $(selector).ajaxChimp(options);
  //     }
  // };

  // $.fn.ajaxChimp = function (options) {
  //     $(this).each(function(i, elem) {
          
  //         var form = $(elem);
  //         var email = form.find('input[type=email]');
  //         var label = form.find('.info');

  //         var settings = $.extend({
  //             'url': form.attr('action'),
  //             'language': 'fr'
  //         }, options);

  //         var url = settings.url.replace('/post?', '/post-json?').concat('&c=?');

  //         form.attr('novalidate', 'true');
  //         email.attr('name', 'EMAIL');

  //         form.submit(function () {

  //             var msg;
  //             function successCallback(resp) {

  //                 if (resp.result === 'success') {
  //                     console.log('success')
  //                     msg = 'Nous vous avons envoyé un mail de confirmation. Validez votre inscription en cliquant sur le lien fourni. Merci.';
  //                     label.removeClass('error').addClass('valid');
  //                     email.removeClass('error').addClass('valid');
  //                     form.addClass('success');
  //                 } else {
                      
  //                     email.removeClass('valid').addClass('error');
  //                     label.removeClass('valid').addClass('error');
  //                     var index = -1;
  //                     try {
  //                         var parts = resp.msg.split(' - ', 2);
  //                         if (parts[1] === undefined) {
  //                             msg = resp.msg;
  //                         } else {
  //                             var i = parseInt(parts[0], 10);
  //                             if (i.toString() === parts[0]) {
  //                                 index = parts[0];
  //                                 msg = parts[1];
  //                             } else {
  //                                 index = -1;
  //                                 msg = resp.msg;
  //                             }
  //                         }
  //                     }
  //                     catch (e) {
  //                         index = -1;
  //                         msg = resp.msg;
  //                     }
  //                 }

  //                 // Translate and display message
  //                 if (
  //                     settings.language !== 'en'
  //                     && $.ajaxChimp.responses[msg] !== undefined
  //                     && $.ajaxChimp.translations
  //                     && $.ajaxChimp.translations[settings.language]
  //                     && $.ajaxChimp.translations[settings.language][$.ajaxChimp.responses[msg]]
  //                 ) {
  //                     msg = $.ajaxChimp.translations[settings.language][$.ajaxChimp.responses[msg]];
  //                 }
  //                 label.html(msg);

  //                 label.show(2000);
  //                 if (settings.callback) {
  //                     settings.callback(resp);
  //                 }
  //             }

  //             var data = {};
  //             var dataArray = form.serializeArray();
  //             $.each(dataArray, function (index, item) {
  //                 data[item.name] = item.value;
  //             });

  //             $.ajax({
  //                 url: url,
  //                 data: data,
  //                 success: successCallback,
  //                 dataType: 'jsonp',
  //                 error: function (resp, text) {
  //                     console.log('mailchimp ajax submit error: ' + text);
  //                 }
  //             });

  //             // Translate and display submit message
  //             var submitMsg = 'Submitting...';
  //             if(
  //                 settings.language !== 'en'
  //                 && $.ajaxChimp.translations
  //                 && $.ajaxChimp.translations[settings.language]
  //                 && $.ajaxChimp.translations[settings.language]['submit']
  //             ) {
  //                 submitMsg = $.ajaxChimp.translations[settings.language]['submit'];
  //             }
  //             label.html(submitMsg).show(2000);

  //             return false;
  //         });
  //     });
  //     return this;
  // };
  // $('#mc-embedded-subscribe-form').ajaxChimp();
};

lightbox = function(){
  var lightbox = GLightbox({
    touchNavigation: true,
    loop: true,
    autoplayVideos: true,
    skin: 'bo',
    openEffect: "none",
    closeEffect: "none"
  });
};

/* ---------------------------------------------------------------------------- */
// Home slider
/* ---------------------------------------------------------------------------- */
var flkty = null;
do_flickity = function(){
  var focuses = document.querySelector('.focuses');
  flkty = new Flickity( focuses, {
    // options
    cellAlign: 'left',
    contain: true,
    prevNextButtons: false,
    pageDots: false,
    autoPlay: true,
    selectedAttraction: 0.2,
    friction: 0.8,
    dragThreshold: 10
  });
  // nav elements
  var focusdots = document.querySelector('.focusdots');
  var buttons = focusdots.querySelectorAll('button') ;
  var cellsButtons = Array.prototype.slice.call(buttons);

  // update buttons on select
  flkty.on( 'select', function() {
    var previousSelectedButton = focusdots.querySelector('.is-selected');
    var selectedButton = focusdots.children[ flkty.selectedIndex ];
    previousSelectedButton.classList.remove('is-selected');
    selectedButton.classList.add('is-selected');
  });
  // cell select
  cellsButtons.forEach(button => {    
    button.addEventListener('click', (event) => {
      var index = cellsButtons.indexOf( event.target );
      flkty.select( index );
    });
  });
  window.addEventListener('resize', function(){
    flkty.resize();
  });
};

undo_flickity = function(){     
  if(flkty) {
    flkty.destroy();  
  }
  flkty = null;
};

flickity = function(){  
  enquire.register("screen and (min-width: 80px)", {
      match : function() { do_flickity(); },      
      unmatch : function() { undo_flickity(); },    
      deferSetup : true
  });
};

/* ---------------------------------------------------------------------------- */
// simple grids
/* ---------------------------------------------------------------------------- */

do_grid = function(){
  var elem = document.querySelector('.grid');
  if(!elem) return;
  var msnry = new Masonry( elem, {
    // options
    columnWidth: '.grid-sizer',
    itemSelector: '.item',
    gutter: '.gutter-sizer',
    percentPosition: true,
    isInitLayout: false
  });
  msnry.on( 'layoutComplete', function(){
    elem.classList.add("layoutComplete");
  });
  imagesLoaded(elem, function() {
    msnry.layout();
  });
};

undo_grid = function(){    
  var elem = document.querySelector('.grid');
  var msnry = Masonry.data( elem );
  msnry.destroy();      
};

grid = function(){  
  enquire.register("screen and (min-width: 580px)", {
      match : function() { do_grid(); },      
      unmatch : function() { undo_grid(); },    
      deferSetup : true
  });
};


/* ---------------------------------------------------------------------------- */
// grid on extrapage-titles
/* ---------------------------------------------------------------------------- */

do_extracover = function(){
  var elems = document.querySelectorAll('.extra-gridimg');
  elems.forEach(elem => {
    document.querySelectorAll('.extra-gridimg-item').forEach((item) => {
      // item.style.paddingTop = Math.floor( Math.random() * 5 ) * 40 + "px";
      // item.style.paddingBottom = Math.floor( Math.random() * 2 ) * 40 + "px";
      item.style.setProperty("--paddingTop", Math.floor( Math.random() * 2 ) );
      item.style.setProperty("--paddingBottom", Math.floor( Math.random() ) );
      var tal = Math.random() < 0.33 ? 'left' : Math.random() > 0.66 ? 'right' : 'center';
      item.style.textAlign = tal;
      item.style.setProperty('--align', tal == "left" ? "start" : tal == "right" ? "end" : "center" );
    });
         
    var msnry = new Masonry( elem, {
      itemSelector: '.extra-gridimg-item',
      percentPosition: true,
      columnWidth: '.grid-sizer',
      gutter: '.gutter-sizer',
      isInitLayout: false,
      originLeft: false
    });
    msnry.on( 'layoutComplete', function(){
      elem.classList.add("layoutComplete");
    });
    imagesLoaded(elem, function(){     
      msnry.layout();
    });
  });  
};

undo_extracover = function(){    
  var elems = document.querySelectorAll('.extra-gridimg');
  elems.forEach(elem => {
    var msnry = Masonry.data( elem );
    if(msnry)
      msnry.destroy();    
  });  
};

extracover = function(){
  enquire.register("screen and (min-width: 80px)", {
    match : function() { do_extracover(); },      
    unmatch : function() { undo_extracover(); },    
    deferSetup : true
  });
};



/* ---------------------------------------------------------------------------- */
// Galleries
/* ---------------------------------------------------------------------------- */

do_gallery = function(){
  var galleries = document.querySelectorAll('.gallery');
  if(!galleries) return;
  galleries.forEach(gallery => {
    var hasbig = gallery.dataset.hasbig,
      items = gallery.querySelectorAll('.gallery-item'),
      which = Math.floor( Math.random() * items.length );
    
    items.forEach(function(item, idx){
      if(idx == which && hasbig == "false"){
        item.classList.add('double');
      }
      
      // if(idx == 0){
      //   item.style.paddingTop = Math.floor( Math.random() ) * 80 + 80 + "px";
      //   item.style.paddingBottom = Math.floor( Math.random() * 3 ) * 80 + "px";          
      // } else if( idx == 1){
      //   item.style.paddingTop = 0;
      //   item.style.paddingBottom = Math.floor( Math.random() * 3 ) * 80 + "px";          
      // } else {
      //   item.style.paddingTop = Math.floor( Math.random() * 2 ) * 80 + "px";
      //   item.style.paddingBottom = Math.floor( Math.random() * 2 ) * 80 + "px";
      // }
      if(idx == 0){
        item.style.setProperty("--paddingBottom", Math.floor( Math.random() * 3 ) );          
      } else if( idx == 1){
        item.style.setProperty("--paddingTop", 0);
        item.style.setProperty("--paddingBottom", Math.floor( Math.random() * 3 ) );          
      } else {
        item.style.setProperty("--paddingTop", Math.floor( Math.random() * 2 ) );
        item.style.setProperty("--paddingBottom", Math.floor( Math.random() * 2 ) );
      }
    });
    setTimeout(() => {
      imagesLoaded(gallery, function(){
        var msnry = new Masonry( gallery, {
          itemSelector: '.gallery-item',
          columnWidth: '.grid-sizer',
          gutter: '.gutter-sizer',
          percentPosition: true,
          initLayout: false
        });
        msnry.on( 'layoutComplete', function(){
          gallery.classList.add("layoutComplete");
        });
        msnry.layout();
      });
    }, 150);    
  });
};

undo_gallery = function(){

  var elems = document.querySelectorAll('.gallery');
  var items = document.querySelectorAll('.gallery-item');
  elems.forEach(elem => {
    var msnry = Masonry.data( elem );
    msnry.destroy();    
  });  

  items.forEach(item => {
    item.style.setProperty("--paddingTop", 0 );
    item.style.setProperty("--paddingBottom", 0 );
  });   
};

gallery = function(){
  enquire.register("screen and (min-width: 550px)", {
    match : function() { do_gallery(); },      
    unmatch : function() { undo_gallery(); },    
    deferSetup : true
  });
};


/* ---------------------------------------------------------------------------- */
// sticky helper for page + extra
/* ---------------------------------------------------------------------------- */

do_setCSSProps = function(){
  var extra = document.querySelector('#extra'); 
  if(!extra) return;
  var page = document.querySelector('#page');
  var wh = window.innerHeight;
  var bcrheight = page.getBoundingClientRect().height;  
  var offset = 0 - bcrheight + wh;
  if(offset < 0){
    page.style.setProperty("--offset", offset + "px");
  }       
};

setCSSProps = function() {
  window.addEventListener('load', do_setCSSProps, false);
  window.addEventListener('resize', do_setCSSProps, false);
};


/* ---------------------------------------------------------------------------- */
// Audio players in extra pages
/* ---------------------------------------------------------------------------- */

makeAudioPlayers = function(){
  var playas = [],
    currently_playing = null,
    players = document.querySelectorAll('.media-audio-player');

  playNext = function(idx){
    if (playas[idx + 1]) {
      playas[idx + 1].audio.play();
      currently_playing = playas[idx + 1].audio;
      playas[idx + 1].btn.classList.add('playing');
    }
  };

  players.forEach((player, idx) => {
    
    var audio = player.querySelector("audio");
    if(!audio) return;
    var id = 'js-audio-player-' + idx;
    
    audio.id = id;
    var html_player = document.createElement('div'); 
    html_player.className = "media-player";
    var btn = document.createElement('button');
    btn.textContent = "play/pause";
    btn.className = "playpause";

    html_player.prepend(btn);
    player.prepend(html_player);

    playas.push({
      idx:idx,
      audio:audio,
      btn:btn
    });

    audio.addEventListener('loadedmetadata',function(){
      var d = audio.duration;
      var minutes = parseInt(d / 60, 10);
      var seconds = ("0" + parseInt(d % 60)).slice(-2);
      var duration = document.createElement("span");
      duration.textContent = minutes +":"+seconds;
      html_player.appendChild(duration);
    },false);

    btn.addEventListener('click', function(){
      if (currently_playing) {
        currently_playing.pause();
        currently_playing = null;
      } 
      if (btn.matches('.playing')) {
          audio.pause();
          btn.classList.remove('playing');
      } else {
          audio.play();
          currently_playing = audio;
          var old_current = document.querySelector('.playpause');
          if(old_current) old_current.classList.remove('playing');
          btn.classList.add('playing');
      }
    });
    audio.addEventListener('ended', function(){
      btn.classList.remove('playing');
      playNext(idx);
    });
  });

};


/* ---------------------------------------------------------------------------- */
// Video players in extra pages
/* ---------------------------------------------------------------------------- */

makeVideoPlayers = function(){
  
  var players = document.querySelectorAll('.js-video-player');
  players.forEach(element => {
    var autoplay = element.dataset.autoplay == "true";
    var muted = autoplay;
    controls = autoplay ? [ 'play-large'] : [ 'play', 'progress', 'current-time', 'volume', 'mute', 'fullscreen'];
    var player = new Plyr(element, {
      autoplay: autoplay,
      muted: muted,
      controls: controls
    });  
  });
};

/* ---------------------------------------------------------------------------- */
// Load extrapage via ajax
/* ---------------------------------------------------------------------------- */

  function loadExtra(href){
    
    // history.pushState(null, null, href);
    var node = document.querySelector("[href='"+href+"']");
    var s = node.closest('.extralist-item');
    s.classList.add("extraloading");
    fetch(href)
    .then(function(response) {
      return response.text();
    })
    .then(function(html) {
      // Initialize the DOM parser
      var parser = new DOMParser();
      var doc = parser.parseFromString(html, "text/html");
      var content = doc.querySelector('#extra');
      content.id = "extraloaded";
      
      var closep = document.createElement('p');
      var closebutton = document.createElement('button');
      closep.className = "close";
      content.prepend(closep);
      closep.appendChild(closebutton);
      closebutton.onclick = () => { unloadExtra(); };
      // s.scrollIntoView();
      s.classList.remove("extraloading");
      s.classList.add('extraloaded');
      window.location.hash = "#" + s.id;
      s.after(content);
      
      // do extra stuff
      // grid();
      gallery();
      makeAudioPlayers();
      makeVideoPlayers();
      lightbox();
      content.scrollIntoView();
    })
  
    .catch(function(error) {
      console.log(error);
    });
}

  function unloadExtra(){
    // close + remove content
    var s = document.querySelector('.extralist-item.extraloaded');
    if(s){
      document.querySelector('#extraloaded').remove();
      s.classList.remove("extraloaded");
      s.scrollIntoView();
      // window.location.hash = "";
      history.replaceState("", document.title, window.location.pathname);
    }
}


var ajaxextra = function(){
  var extralinks = document.querySelectorAll('.extralink');
  extralinks.forEach(extralink => {
    var s = extralink.closest(".extralist-item");
    extralink.onclick = (e) => {
      e.preventDefault();
      e.stopPropagation();
      if(s.classList.contains('extraloaded')){        
        unloadExtra();
        // s.parentElement.scrollIntoView();
      } else {
        unloadExtra();
        var href = extralink.getAttribute("href");
        loadExtra(href);
      }
    };
  });
  if(window.location.hash) {
    var hash = window.location.hash,
        is_rdv_hash = hash.indexOf('rdv') !== -1,
        target = document.querySelector(window.location.hash);
    if(target && !is_rdv_hash && window.location.hash != "#extra"){
      unloadExtra();
      loadExtra(target.querySelector('a').getAttribute("href"));
    }
  }
  // window.onpopstate = function(event) {
  //   if(window.location.hash) {
  //     var target = document.querySelector(window.location.hash);
  //     if(target && window.location.hash != "#extra"){
  //       unloadExtra();
  //       loadExtra(target.querySelector('a').getAttribute("href"));
  //     }
  //   }
  // };
};